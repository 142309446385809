import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { RouterProvider } from 'react-router-dom';
import { routes as globalRoutes } from 'global/config/globalRoutes';

import ProtectedRoute from './ProtectedRoute'
import PublicRoute from './PublicRoute'
import { Route } from './types';
import ErrorPage from 'components/404';




const routeNotFound:Route = {
    path: '*',
    element: ErrorPage
};
  
const router: Route[] = [...globalRoutes, routeNotFound];

export default function CreateRouter() {
    const fallbackRoute = {
        element: null,
        errorElement: <ErrorPage />
    };
    const browserRoutes = router.map(
        ({ path, element: Content, isProtected }) =>
            ({
                path,
                errorElement: <ErrorPage />,
                element: isProtected
                    ? (
                        <ProtectedRoute>{<Content />}</ProtectedRoute>
                    )
                    : (
                        <PublicRoute>{<Content />}</PublicRoute>
                    )
            })
    );

    browserRoutes.push(fallbackRoute as any);

    return  <RouterProvider router={createBrowserRouter(browserRoutes)} />
    
};
