/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/alt-text */
import { ASSETS } from 'global/assets'
import './SubmitSuccess.scss'
import Button from 'components/Button'

export default function SubmitSuccess(props: {goToHome: () => any}) {
  return (
    <div className='SubmitSuccess'>
      <div className='SubmitSuccess__centerContainer'>
        <img src={ASSETS.images.landingpage.LandingPCardPhone} width={'100%'} height={249} />
        <span>Boost your business with our smart solutions. We will be in touch shortly</span>
        <Button text='Back to Homepage' onClick={props.goToHome}/>
      </div>

      <ASSETS.svgs.SignupBg1 className='SubmitSuccess__bg__bg1'/>
      <ASSETS.svgs.SignupBg2 className='SubmitSuccess__bg__bg2'/>
    </div>
  )
}
