/* eslint-disable jsx-a11y/alt-text */
import { Carousel } from 'react-responsive-carousel';
import './ShowcaseImages.scss';
import { imageShowcase } from 'containers/LandingPage/contentconfigs';
import { useRef, useState } from 'react';
export default function ShowcaseImages() {
    const controls = useRef<{[key: string]: {event: (ee: any) => any, active:boolean, index: number}}>({})
    const delayRef = useRef<NodeJS.Timeout>();
    const [elementControls, setElementConrols] = useState<{[key: string]: {event: (ee: any) => any, active:boolean, index: number}}>({})
    return (
        <>
            <Carousel className='ShowcaseImages' showIndicators={true} renderIndicator={(event, active, index, label) => {
                controls.current[`${index}`] = {active, index, event}
                if(index === imageShowcase.length-1){
                    clearTimeout(delayRef.current)
                    delayRef.current = setTimeout(() => {
                        setElementConrols({...controls.current})
                    }, 300);
                }
                return null;
            }} swipeable={true} infiniteLoop={true} autoPlay={false} emulateTouch={true} showThumbs={false} showArrows={false} showStatus={false}>
                {imageShowcase.map((img, index) => (
                    <div key={index} className="ShowcaseImages__content" style={{alignItems: img.textPositionRight ? 'flex-end' : undefined}}>
                        <div className='bg' style={{
                            backgroundImage: `url(${img.image})`
                        }} />
                        <div className={`ShowcaseImages__content__text ${img.textPositionRight ? "ShowcaseImages__content__text__bgRight" :"ShowcaseImages__content__text__bgLeft"}`} style={{paddingBottom: img.paddingBottom, paddingTop: img.paddingTop}}>
                            <div className='title'>{img.title}</div>
                            <div className='description'>{img.description}</div>
                        </div>
                    </div>
                ))}
            </Carousel>
            <div className='ShowcaseImages__controls'>
                {Object.values(elementControls).map((control, index) => (
                    <div key={index} className='ShowcaseImages__controls__control' onClick={control.event} style={{
                        backgroundColor: control.active ? '#2E59D9' : undefined,
                        width: control.active ? '36px' : undefined,
                    }}/>
                ))}
            </div>
        </>
    )
}

