/* eslint-disable jsx-a11y/alt-text */
import { WhyChooseStormItemsType } from 'containers/LandingPage/types';
import './ChooseStoremItem.scss';

export default function ChooseStoremItem(props: {item: WhyChooseStormItemsType}): JSX.Element {
    const Image = () => (
        <img src={props.item.img} className='ChooseStoremItem__img'/>
    )
    const Content = () => {
        const Icon = props.item.icon;
        return (
            <div className='ChooseStoremItem__textContent'  style={{
                alignItems: 'flex-start'
            }}>
                <div className='ChooseStoremItem__textContent__content'>
                    <Icon />
                    <div className='ChooseStoremItem__textContent__content__title'>{props.item.title}</div>
                    <div className='ChooseStoremItem__textContent__content__description'>{props.item.description}</div>
                </div>
            </div>
        );
    }
    return (
        <div className='ChooseStoremItem' >
            {props.item.rtl ? (<>
                <Content />
                <Image />
            </>):(<>
                <Image />
                <Content />
            </>)}
        </div>
    )
}
