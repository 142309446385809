import './StormBusinessCard.scss'

export default function StormBusinessCard() {
  return (
    <div className='StormBusinessCard'>
        <h3>Storm Business Card</h3>
        <div className='StormBusinessCard__options'>
            <div className='StormBusinessCard__options__storm'>
                <div className='StormBusinessCard__options__type'>Storm Card</div>
                <div className='StormBusinessCard__options__price'>Free</div>
                <div className='StormBusinessCard__options__description'>Annual Fee</div>
            </div>
            <div className='StormBusinessCard__options__virtual'>
                <div className='StormBusinessCard__options__type'>Virtual Card</div>
                <div className='StormBusinessCard__options__price'>$0</div>
                <div className='StormBusinessCard__options__description'>included with signup</div>
            </div>
        </div>

        <div className='StormBusinessCard__options'>
            <div className='StormBusinessCard__options__storm'>
                <div className='StormBusinessCard__options__type'>Storm Mobile Application</div>
                <div className='StormBusinessCard__options__price'>Free</div>
                <div className='StormBusinessCard__options__description'>included with signup</div>
            </div>
            <div className='StormBusinessCard__options__virtual'>
                <div className='StormBusinessCard__options__type'>Virtual Card</div>
                <div className='StormBusinessCard__options__price'>15-25%</div>
                <div className='StormBusinessCard__options__description'>credit limit assigned instantly during signup</div>
            </div>
        </div>
    </div>
  )
}
