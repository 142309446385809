/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/alt-text */
import { ASSETS } from 'global/assets'
import './PublicPageLayout.scss'
import { Link, useLocation } from 'react-router-dom'
import { GLOBAL_ROUTES } from 'global/config/constants'
import ApplyNowBtn from 'components/ApplyNowBtn'
import MobileNav from 'components/MobileNav/MobileNav'
import ReactGA from 'react-ga4';
import { useEffect } from 'react'

export default function PublicPageLayout(props: {children: JSX.Element | JSX.Element[], headerChildrens?: JSX.Element | JSX.Element[]}) {
    const location = useLocation();
    
    useEffect(() => {
        const data = {
            hitType: 'pageview',
            page: location.pathname,
        };
        // console.log('location.pathname', location.pathname)
        ReactGA.send(data);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [location.pathname]);


    return (
        <div className='PublicPageLayout-container'>
            <div className='PublicPageLayout-container__header'>
                {props.headerChildrens ? props.headerChildrens : <>
                    <Link to={GLOBAL_ROUTES.HOME}>
                        <ASSETS.svgs.Logo width={145} height={51} />
                    </Link>
                    <div className='PublicPageLayout-container__header__menu'>
                        <Link to={GLOBAL_ROUTES.HOME} className={`PublicPageLayout-container__header__menu__item ${location.pathname === GLOBAL_ROUTES.HOME ? 'PublicPageLayout-container__header__menu__item__active' : ''} `} >
                            Why Storm?
                        </Link>
                        <Link to={GLOBAL_ROUTES.PRICING} className={`PublicPageLayout-container__header__menu__item ${location.pathname === GLOBAL_ROUTES.PRICING ? 'PublicPageLayout-container__header__menu__item__active' : ''} `} >
                            Pricing
                        </Link>
                        <Link to={GLOBAL_ROUTES.ABOUT_US} className={`PublicPageLayout-container__header__menu__item ${location.pathname === GLOBAL_ROUTES.ABOUT_US ? 'PublicPageLayout-container__header__menu__item__active' : ''} `} >
                            About Us
                        </Link>
                    </div>
                    <span className='PublicPageLayout-container__header__btn'><ApplyNowBtn /></span>

                    <MobileNav />
                </>}
            </div>
            {props.children}
        </div>
    )
}
