import './Checkbox.scss'

export default function Checkbox(props: {value: string, checked?:boolean, onChange?: (e:any) => any}) {
  return (
    <span className='Checkbox'>
      <input type='checkbox' className='Checkbox__input' checked={props.checked} onChange={props.onChange}/>
      <span className='Checkbox__checkmark'/>
    </span>
  )
}
