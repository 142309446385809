/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';

import './MobileNav.scss';
import { useNavigate } from 'react-router-dom';
import { GLOBAL_ROUTES } from 'global/config/constants';
import ApplyNowBtn from 'components/ApplyNowBtn';

const MobileNav = () => {
    const navigation = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const onNavToggle = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        // disabling scroll when popup opens
        if (isOpen) {
            // document.querySelector('html').style.overflow = 'hidden';
            // document.querySelector('body').style.overflow = 'hidden';
        } else {
            // document.querySelector('html').style.overflow = 'auto';
            // document.querySelector('body').style.overflow = 'auto';
        }
    }, [isOpen]);

    const onItemClick = (route) => {
        setIsOpen(!isOpen);
        navigation(route)
    };
    return (
        <div className={`MobileNav ${isOpen ? 'MobileNav--show' : ''}`}>
            <div className='MobileNav__button'>
                <div className='MobileNav__button__background'>
                    <div className='MobileNav__button__background__ratio'/>
                </div>
                <div className='MobileNav__button__bars-container' onClick={onNavToggle}>
                    <div className='MobileNav__button__bars-container__bar' />
                    <div className='MobileNav__button__bars-container__bar' />
                    <div className='MobileNav__button__bars-container__bar' />
                    <div className='MobileNav__button__bars-container__bar' />
                </div>
            </div>
            <div className='MobileNav__nav-contents' />
            <ul className='MobileNav__nav-contents__items-container'>
                {navItems.map((item) => (
                    <li className='MobileNav__nav-contents__items-container__item' key={item.id} onClick={() => onItemClick(item.route)}>
                        {item.name}
                    </li>
                ))}
            </ul>

            <div className='MobileNav__button-row'>
                <span className='MobileNav__button-row__item'><ApplyNowBtn  /></span>
            </div>
        </div>
    );
};

const navItems = [
    {
        id: 1,
        name: 'Why Storm?',
        route: GLOBAL_ROUTES.HOME
    },
    {
        id: 3,
        name: 'Pricing',
        route: GLOBAL_ROUTES.PRICING
    },
    {
        id: 2,
        name: 'About Us',
        route: GLOBAL_ROUTES.ABOUT_US
    }
];

export default MobileNav;
