import { ASSETS } from "global/assets";
import { LandingPageShowcaseImage, WhyChooseStormItemsType } from "./types";


export const imageShowcase: LandingPageShowcaseImage[] = [
    {
        image: ASSETS.images.landingpage.LandingPImg1,
        title: 'Grow your business wisely',
        description: 'The sustainable small business loan spend card ready at your fingertips ',
        paddingTop: '200px',
    },
    {
        image: ASSETS.images.landingpage.LandingPImgAsian3,
        title: 'Rapid signup',
        description: 'Get started in as little as a few minutes by sharing your registered business information',
        textPositionRight: true,
        paddingTop: '200px',
    },
    {
        image: ASSETS.images.landingpage.LandingPImgAsian6,
        title: 'Convenience at your fingertips',
        description: 'Spend on your business as you normally would with any other card',
        paddingTop: '260px',
    },
    {
        image: ASSETS.images.landingpage.LandingPImgAsian5,
        title: 'Spend online, offline or on your mobile device',
        description: 'Storm issued cards support the latest in contactless and tokenization technologies',
        paddingTop: '260px',
    },
    {
        image: ASSETS.images.landingpage.LandingPImgAsian2,
        title: 'Handle unexpected surprises with up to $50,000 credit',
        description: 'With dynamically granted credit limit, Storm cards can offer you and your business credit when you need it'
    },
    {
        image: ASSETS.images.landingpage.LandingPImgAsian4,
        title: 'Built from the ground up to be different',
        description: 'We have improved business lending at the core, by using your data effectively to issue credit',
        paddingBottom: '260px',
    },
    
    // {
    //     image: ASSETS.images.landingpage.LandingPImgAsian1,
    //     title: 'Lorem Ipsum',
    //     description: 'Korem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit ',
    //     textPositionRight: true,
    // },
    // {
    //     image: ASSETS.images.landingpage.LandingPImgAsian2,
    //     title: 'Lorem Ipsum',
    //     description: 'Korem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit '
    // },
    // {
    //     image: ASSETS.images.landingpage.LandingPImgAsian3,
    //     title: 'Lorem Ipsum',
    //     description: 'Korem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit ',
    //     textPositionRight: true,
    // },
    // {
    //     image: ASSETS.images.landingpage.LandingPImgAsian4,
    //     title: 'Lorem Ipsum',
    //     description: 'Korem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit '
    // },
    // {
    //     image: ASSETS.images.landingpage.LandingPImgAsian5,
    //     title: 'Lorem Ipsum',
    //     description: 'Korem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit '
    // },
    // {
    //     image: ASSETS.images.landingpage.LandingPImgAsian6,
    //     title: 'Lorem Ipsum',
    //     description: 'Korem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit '
    // },
]


export const whyChooseStormItems: WhyChooseStormItemsType[] = [
    {
        rtl: false,
        img: ASSETS.images.landingpage.LandingPLaptopCard,
        icon: ASSETS.svgs.Airplane,
        title: 'Instant approval',
        description: 'Get approved instantly using our mobile app, receive your virtual card in minutes',
    },
    {
        rtl: true,
        img: ASSETS.images.landingpage.LandingPCardScan,
        icon: ASSETS.svgs.Card,
        title: 'Low-cost access to funding for your business',
        description: 'Gain access to credit when you need it to help expand your business',
    },
    {
        rtl: false,
        img: ASSETS.images.landingpage.LandingPCardPhone,
        icon: ASSETS.svgs.Pricing,
        title: 'Transparent pricing',
        description: 'Pricing that is easy to understand - simple fees with no hidden charges',
    },
    {
        rtl: true,
        img: ASSETS.images.landingpage.LandingPCardPhoneScan,
        icon: ASSETS.svgs.Access,
        title: 'Access to a full suit of payment solutions',
        description: 'Receive your very own debit account to store business funds, with full transaction history and reporting',
    },
    {
        rtl: false,
        img: ASSETS.images.landingpage.LandingPCardHold,
        icon: ASSETS.svgs.Card2,
        title: 'Built-in features that are hard to beat',
        description: 'Get access to simple spend controls and spend management for you and your employees ',
    },
    {
        rtl: true,
        img: ASSETS.images.landingpage.LandingPPhoneScan,
        icon: ASSETS.svgs.Lock,
        title: 'Secured by the highest industry standards',
        description: 'Compliant with PCI-DSS with your card issued by top international schemes',
    }
];


export const stormBenefits = [
    {
        title: 'Get access to financing immediately',
        storm: true,
        bank: false,
        creditCard: false,
        Icon: ASSETS.svgs.MingcuteSend
    },
    {
        title: 'Rapid approval',
        storm: true,
        bank: false,
        creditCard: true,
        Icon: ASSETS.svgs.MDIApproval
    },
    {
        title: 'Competitive interest rates',
        storm: true,
        bank: false,
        creditCard: true,
        Icon: ASSETS.svgs.FluentMoney
    },
    {
        title: 'Transparent pricing with no surprises',
        storm: true,
        bank: false,
        creditCard: false,
        Icon: ASSETS.svgs.CubeTransparent
    },
    {
        title: 'Interest free periods',
        storm: true,
        bank: false,
        creditCard: true,
        Icon: ASSETS.svgs.HealthSchedule
    },
    {
        title: 'Cloud accounting integration out of the box',
        storm: true,
        bank: true,
        creditCard: false,
        Icon: ASSETS.svgs.ICBaselineCloude
    },
];