/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/alt-text */
import PublicPageLayout from "components/PublicPageLayout";
import './PrivacyPolicyPage.scss';
export default function PrivacyPolicyPage() {
    return (
        <PublicPageLayout headerChildrens={(<></>)}>
            <p className="PrivacyPolicyPage__p" style={{marginTop: '60px'}}><strong><span >GoStorm Privacy Policy</span></strong></p>
            <p className="PrivacyPolicyPage__p"><span >Your privacy is very important to us. At GoStorm we are committed to protecting your privacy and your personal data. This Privacy Policy (&quot;Policy&quot;) explains what data we collect, why we collect it, and how we use it. It also details your rights regarding your personal information.</span></p>
            <p className="PrivacyPolicyPage__p"><strong><span >What is Personal Information?</span></strong></p>
            <p className="PrivacyPolicyPage__p"><span >Personal information is any data that can be used to identify a specific individual.</span><span >&nbsp;&nbsp;</span><span >Once information is de-identified or anonymized and can no longer be linked to a specific person, we may use it for any business, research, or statistical purpose.</span></p>
            <p className="PrivacyPolicyPage__p"><strong><span >What Does This Policy Apply To?</span></strong></p>
            <p className="PrivacyPolicyPage__p"><span >This policy applies to all GoStorm websites, apps, and services you access, including mobile applications, webpages associated with those apps and services, and any equivalent or substitute versions of these (collectively, &quot;Sites&quot;). It also covers your use of our products, services, tools, and features, whether accessed online (through our website or app) or in person, as well as when you sign up for newsletters, interact with us, or apply for services.</span></p>
            <p className="PrivacyPolicyPage__p"><span >If you have separate agreements with GoStorm, those terms may prevail over this Policy for specific services.</span></p>
            <p className="PrivacyPolicyPage__p"><span >It&apos;s important to read and understand this Policy along with our Terms and Conditions. If you disagree with either document, you cannot access or use our Sites or Services.</span></p>
            <p className="PrivacyPolicyPage__p"><strong><span >What Information Do We Collect?</span></strong></p>
            <p className="PrivacyPolicyPage__p"><span >We collect information, including personal data, from you through automated methods or from third parties. We only collect the minimum amount necessary to provide our services.</span></p>
            <p className="PrivacyPolicyPage__p"><strong><span >Information You Provide:</span></strong></p>
            <p className="PrivacyPolicyPage__p"><em><span >Identity and Contact:</span></em><span >&nbsp;This includes your full name, email address, mailing address, phone number, password, date of birth, social security number (or equivalent national identification number), taxpayer identification number, citizenship, job title, and verification information to register you for our services.</span></p>
            <p className="PrivacyPolicyPage__p"><em><span >Business Information:</span></em><span >&nbsp;This includes details about your business, such as type of business, registered and business addresses, financial statements, organizational documents, ownership information, director and employee information, and sales volume.</span></p>
            <p className="PrivacyPolicyPage__p"><em><span >Banking Information:</span></em><span >&nbsp;This includes bank statements, banking and payment history, and credit card information to assess your financial situation.</span></p>
            <p className="PrivacyPolicyPage__p"><em><span >Vendor Preferences:</span></em><span >&nbsp;You can provide information about preferred third-party vendors, websites, or services to allow us to register or set up vendor accounts or provide specific services.</span></p>
            <p className="PrivacyPolicyPage__p"><em><span >Profile and Preferences:</span></em><span >&nbsp;This includes your preferred services, interests, feedback, and survey responses to personalize your experience.</span></p>
            <p className="PrivacyPolicyPage__p"><em><span >Marketing Preferences:</span></em><span >&nbsp;This includes your subscriptions to our newsletters and blogs to keep you informed about GoStorm and our services.</span></p>
            <p className="PrivacyPolicyPage__p"><em><span >Testimonials:</span></em><span >&nbsp;You may choose to share your name as a GoStorm customer in a testimonial.</span></p>
            <p className="PrivacyPolicyPage__p"><strong><span >Information We Collect Automatically:</span></strong></p>
            <p className="PrivacyPolicyPage__p"><span >We use cookies, web beacons, and similar technologies to gather information that supports our registration process, remembers your preferences and account settings, tracks visits, and helps us analyze and compile user activity statistics.</span></p>
            <p className="PrivacyPolicyPage__p"><span >This information may include your computer browser type, geolocation, internet protocol (IP) address, pages visited, and average time spent on our Sites.</span></p>
            <p className="PrivacyPolicyPage__p"><span >We may use this information to alert you about software compatibility issues or to improve the design and functionality of our website and mobile app.</span></p>
            <p className="PrivacyPolicyPage__p"><span >When you use our mobile app, we may collect your unique device identifier assigned by the manufacturer, carrier, or operating system (&quot;Device ID&quot;). This helps us identify your device for storing app preferences, user activities, and other data.</span></p>
            <p className="PrivacyPolicyPage__p"><strong><span >Information from Third Parties:</span></strong></p>
            <p className="PrivacyPolicyPage__p"><span >With your permission, we may collect your account and other personal information from third-party websites you register under your account via our services, including payment and delivery providers.</span></p>
            <p className="PrivacyPolicyPage__p"><span >We may use third parties to gather your personal information, business information, and banking information from financial institutions or registered agents to provide services.</span></p>
            <p className="PrivacyPolicyPage__p"><span >We may collect information about your interests and preferences from our business partners, referral services, analytics providers, and search information providers.</span></p>
            <p className="PrivacyPolicyPage__p"><span >We may also collect information about you from mailing list providers, publicly available sources, and other third parties to process and manage your application and transactions.</span></p>
            <p className="PrivacyPolicyPage__p"><span >We may allow third-party service providers to use cookies or other tracking technologies to collect information about your browsing activity across different websites after you use our Sites.</span></p>
            <p className="PrivacyPolicyPage__p"><span >In rare cases, we may obtain personal information from recruiters or other entities as part of a background check for a job opening at GoStorm.</span></p>
            <p className="PrivacyPolicyPage__p"><strong><span >Why Do We Collect Your Information?</span></strong></p>
            <p className="PrivacyPolicyPage__p"><span >We have legal reasons to process your personal information, including:</span></p>
            <p className="PrivacyPolicyPage__p"><span >Contract: to fulfill our contractual obligations to you.</span></p>
            <p className="PrivacyPolicyPage__p"><span >Legitimate Interest: as detailed in &quot;How We Use Your Information&quot;.</span></p>
            <p className="PrivacyPolicyPage__p"><span >Legal or Regulatory Obligations: to comply with legal and regulatory requirements.</span></p>
            <p className="PrivacyPolicyPage__p"><span >Except for your marketing and cookie preferences, we do not rely on consent as a legal basis for processing your personal information.</span></p>
            <p className="PrivacyPolicyPage__p"><strong><span >How We Use Your Information</span></strong></p>
            <p className="PrivacyPolicyPage__p"><span >We will only use your personal information for the purposes for which it was collected, unless one or more of the following apply:</span></p>
            <p className="PrivacyPolicyPage__p"><span >The new use is compatible with the original purpose.</span></p>
            <p className="PrivacyPolicyPage__p"><span >We have notified you of the new use and given you an opportunity to opt-out.</span></p>
            <p className="PrivacyPolicyPage__p"><span >The new use is permitted or required by law.</span></p>
            <p className="PrivacyPolicyPage__p"><strong><span >Who Do We Share Your Information With?</span></strong></p>
            <p className="PrivacyPolicyPage__p"><span >We may share your personal information with the following parties:</span></p>
            <p className="PrivacyPolicyPage__p"><span >Internal Third Parties: These include our subsidiaries and affiliates.</span></p>
            <p className="PrivacyPolicyPage__p"><span >External Third Parties: These include service providers, agents, subcontractors, consultants, insurance providers, financial institutions, funders, payment processors, business partners, referral partners, recruiters, government entities (for legal requirements), or other third parties (as required by law, to comply with regulations, or to protect our interests).</span></p>
            <p className="PrivacyPolicyPage__p"><span >We require all third parties to respect the security of your personal information and to treat it in accordance with the law. They are only allowed to process your data for specified purposes and in accordance with our instructions.</span></p>
            <p className="PrivacyPolicyPage__p"><strong><span >How Long Do We Keep Your Information?</span></strong></p>
            <p className="PrivacyPolicyPage__p"><span >We will retain your personal information only for as long as necessary to fulfill the purposes we collected it for, including legal, regulatory, administrative, security, compliance, contractual, tax, accounting, or reporting requirements. We may retain your data for a longer period in case of a complaint or potential litigation.</span></p>
            <p className="PrivacyPolicyPage__p"><span >We use a retention schedule that considers the amount, nature, and sensitivity of the data, the potential risk of unauthorized use or disclosure, the processing purposes, and applicable legal requirements.</span></p>
            <p className="PrivacyPolicyPage__p"><span >We will continue to store certain customer information for seven (7) years after they cease being customers, as required by law. You may also have the right to request deletion of your personal information (see &quot;Request to Erase Your Personal Data&quot; below).</span></p>
            <p className="PrivacyPolicyPage__p"><strong><span >How We Secure Your Information</span></strong></p>
            <p className="PrivacyPolicyPage__p"><span >We take steps to protect your personal information from unauthorized access, disclosure, alteration, or destruction. These measures include firewalls, SSL/TLS encryption techniques, and authentication procedures. We also restrict access to your information to authorized personnel who have a business need to know.</span><span >&nbsp;&nbsp;</span><span >They are subject to a duty of confidentiality and are trained on our privacy practices.</span></p>
            <p className="PrivacyPolicyPage__p"><span >We have procedures in place to deal with any suspected personal data breach and will notify you and any applicable regulator if required by law.</span></p>
            <p className="PrivacyPolicyPage__p"><span >The internet and email transmission are not completely secure.</span><span >&nbsp;&nbsp;</span><span >It&apos;s best to be cautious about what information you send us via email.</span><span >&nbsp;&nbsp;</span><span >You can report any suspected security vulnerabilities to our security team at [email address removed].</span></p>
            <p className="PrivacyPolicyPage__p"><strong><span >What Are Your Rights?</span></strong></p>
            <p className="PrivacyPolicyPage__p"><span >Under certain data protection laws, you have rights regarding your personal information. These rights may include:</span></p>
            <p className="PrivacyPolicyPage__p"><span >Request Access to Your Personal Data: You can receive a copy of the personal information we hold about you.</span></p>
            <p className="PrivacyPolicyPage__p"><span >Request Correction of Your Personal Data: You can request correction of any inaccurate or incomplete data we hold about you.</span></p>
            <p className="PrivacyPolicyPage__p"><span >Request Erasure of Your Personal Data: You can request deletion of your personal information under certain circumstances. However, there may be legal reasons why we cannot fulfill your request, and we will notify you of these reasons if applicable.</span></p>
            <p className="PrivacyPolicyPage__p"><span >Object to Processing of Your Personal Data: You can object to the processing of your data when it&apos;s based on our legitimate interests or those of a third party. You can also object to processing for direct marketing purposes.</span></p>
            <p className="PrivacyPolicyPage__p"><span >Request Restriction of Processing of Your Personal Data: You can ask us to suspend processing of your data in certain situations, such as if you need to establish the accuracy of your data.</span></p>
            <p className="PrivacyPolicyPage__p"><span >Request Transfer of Your Personal Data: You can request transfer of your personal information to you or a third party in a commonly used machine-readable format. This right applies to data you initially provided to us. There may be some data we need to retain for legal or other business purposes.</span></p>
            <p className="PrivacyPolicyPage__p"><span >Right to Withdraw Consent: You can withdraw your consent at any time for processing your data. However, this will not affect the lawfulness of processing done before you withdrew your consent. Withdrawing consent may prevent us from providing certain services to you.</span></p>
            <p className="PrivacyPolicyPage__p"><strong><span >What We May Need From You</span></strong></p>
            <p className="PrivacyPolicyPage__p"><span >We will handle your requests and exercise your rights according to applicable law.</span><span >&nbsp;&nbsp;</span><span >There may be legal reasons why we cannot fulfill all requests. We may ask you to verify your identity to ensure your right to access your data or exercise other rights.</span><span >&nbsp;&nbsp;</span><span >This is a security measure to prevent unauthorized disclosure of your information.</span><span >&nbsp;&nbsp;</span><span >We may also contact you for more information to expedite your request.</span></p>
            <p className="PrivacyPolicyPage__p"><span >There is typically no fee to access your data or exercise your other rights.</span><span >&nbsp;&nbsp;</span><span >However, we may charge a reasonable fee for requests that are unfounded, repetitive, or excessive.</span><span >&nbsp;&nbsp;</span><span >Alternatively, we may refuse such requests.</span></p>
            <p className="PrivacyPolicyPage__p"><strong><span >What are your rights as a Singapore Resident&nbsp;</span></strong></p>
            <table cellSpacing="0" cellPadding="0" className="PrivacyPolicyPage__table">
                <tbody>
                    <tr>
                        <td className="PrivacyPolicyPage__table__col_header1">
                            <p className="PrivacyPolicyPage__table__col_header1__text"><strong><span >Data collection category&nbsp;</span></strong></p>
                        </td>
                        <td className="PrivacyPolicyPage__table__col_header2">
                            <p className="PrivacyPolicyPage__table__col_header2__text"><strong><span >Disclosure policy&nbsp;</span></strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td className="PrivacyPolicyPage__table__col_td1">
                            <p className="PrivacyPolicyPage__table__col_td1__text"><span >Your Identity and Contact Information</span></p>
                        </td>
                        <td className="PrivacyPolicyPage__table__col_td2">
                            <p className="PrivacyPolicyPage__table__col_td2__text"><span >Internal&nbsp;</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td className="PrivacyPolicyPage__table__col_td1">
                            <p className="PrivacyPolicyPage__table__col_td1__text"><span >Your Profile Information and User Preferences</span></p>
                        </td>
                        <td className="PrivacyPolicyPage__table__col_td2">
                            <p className="PrivacyPolicyPage__table__col_td2__text"><span >Internal</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td className="PrivacyPolicyPage__table__col_td1">
                            <p className="PrivacyPolicyPage__table__col_td1__text"><span >Your Marketing and Communication Preferences</span></p>
                        </td>
                        <td className="PrivacyPolicyPage__table__col_td2">
                            <p className="PrivacyPolicyPage__table__col_td2__text"><span >Internal / External (e.g. third party marketing communication agencies)&nbsp;</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td className="PrivacyPolicyPage__table__col_td1">
                            <p className="PrivacyPolicyPage__table__col_td1__text"><span >Business and Banking Information</span></p>
                        </td>
                        <td className="PrivacyPolicyPage__table__col_td2">
                            <p className="PrivacyPolicyPage__table__col_td2__text"><span >Internal / External (where we need to deduct payments to repay loans)</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td className="PrivacyPolicyPage__table__col_td1">
                            <p className="PrivacyPolicyPage__table__col_td1__text"><span >Internet activity or other electronic network activity information</span></p>
                        </td>
                        <td className="PrivacyPolicyPage__table__col_td2">
                            <p className="PrivacyPolicyPage__table__col_td2__text"><span >Internal / External (e.g. traffic analysis services)&nbsp;</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td className="PrivacyPolicyPage__table__col_td1">
                            <p className="PrivacyPolicyPage__table__col_td1__text"><span >Geolocation data</span></p>
                        </td>
                        <td className="PrivacyPolicyPage__table__col_td2">
                            <p className="PrivacyPolicyPage__table__col_td2__text"><span >Internal / External (e.g. risk analysis engines)&nbsp;</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td className="PrivacyPolicyPage__table__col_td1">
                            <p className="PrivacyPolicyPage__table__col_td1__text"><span >Employment or recruiting related information</span></p>
                        </td>
                        <td className="PrivacyPolicyPage__table__col_td2">
                            <p className="PrivacyPolicyPage__table__col_td2__text"><span >Internal&nbsp;</span></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className="PrivacyPolicyPage__p"><span >&nbsp;</span></p>
            <p className="PrivacyPolicyPage__p"><span >The categories of sources from which we collect Personal Data, our purposes for using your Personal Data as well as your rights to access, rectify and erase your Personal Data are set forth above in this Policy.</span></p>
            <p className="PrivacyPolicyPage__p" style={{paddingBottom: '60px'}}><span >GoStorm does not &ldquo;sell&rdquo; your Personal Data, nor do we have actual knowledge of any sale of personal information of minors under 18 years of age. GoStorm will not discriminate against any individual for exercising their rights under the CCPA. In the event you require an authorized agent to act on your behalf to make a verifiable request related to your Personal Data, please contact us at&nbsp;</span><a href="mailto:info@gostorm.io" style={{textDecoration: 'none'}}><u><span style={{color: '#467886'}}>info@gostorm.io</span></u></a></p>
        </PublicPageLayout>
    )
}



