/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import Select, { StylesConfig, type DropdownIndicatorProps, components, ActionMeta  } from 'react-select'
import './SelectSearch.scss';
import { ASSETS } from 'global/assets';

export default function SelectSearch(props: {label?: string;onChange?: ((newValue: unknown, actionMeta: ActionMeta<unknown>) => void) | undefined;
  options: {value: any; label: string}[]; id?:string, placeholder?: string, className?: string, labelClassName?: string, rightElement?: React.JSX.Element, errorMessage?: string; containerClass?: string}) {
  return (
    <div className={`InputGroup SelectSearch ${props.containerClass}`}>
            {props.label && <label htmlFor={props.id} className={`InputGroup__label ${props.labelClassName}`}>{props.label}</label>}
            <div className='InputGroup__inputContent'>
                <Select 
                    placeholder={props.placeholder}
                    components={{DropdownIndicator}}
                    styles={colourStyles}
                    options={props.options}
                    onChange={props.onChange}
                    className={`InputGroup__input SelectSearch__input ${props.className}`}/>
                {props.rightElement && (
                  <div className="InputGroup__group-append">
                    {props.rightElement}
                  </div>
                )}
            </div>
            {props.errorMessage && <div className='warning-message TextInput__warning-message'>{props.errorMessage}</div>}
        </div>
  )
}

const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => {
  return (
    <components.DropdownIndicator {...props}>
      <ASSETS.svgs.TriangelDown />
    </components.DropdownIndicator>
  );
};

const colourStyles: StylesConfig = {
  control: (styles, { isFocused }) => {
    return { ...styles, backgroundColor: '#E7E8EC', border: isFocused ? '1px solid' : '0px' };
  },
  input: (styles) => ({ ...styles, color: "#495057", fontSize: '1rem' }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles) => ({ ...styles }),
  indicatorSeparator: (styles) => ({ ...styles , display: 'none'}),
  dropdownIndicator: (styles) => ({ ...styles }),
};

