/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/alt-text */
import { ASSETS } from 'global/assets';
import './LandingPageFooter.scss';
import { Link } from 'react-router-dom';
import { GLOBAL_ROUTES } from 'global/config/constants';

export default function LandingPageFooter() {
  return (
    <div className='LandingPageFooter'>
        <ASSETS.svgs.Logo width={188} height={65}/>

        <div className='LandingPageFooter__links'>
            {/* <div>FAQ</div> */}
            {/* <div>Terms & Conditions</div> */}
            {/* <div><a href={ASSETS.files.PrivacyPolicy} target='_blank' rel="noreferrer">Privacy Policy</a></div> */}
            <div>
              <Link to={GLOBAL_ROUTES.PRIVACY_POLICY}>
                Privacy Policy
              </Link>
            </div>
        </div>

        <div className='LandingPageFooter__contacts'>
            <div>Contact</div>
            <div><a href='mailTo:info@gostorm.app'>info@gostorm.app</a></div>
            <div>Launching soon in 2024 in Singapore</div>
        </div>
    </div>
  )
}
