import { GLOBAL_ROUTES } from "global/config/constants";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { SignupData } from "./types";
import { useRegister } from "global/api/registration";
import * as Bowser from 'bowser';
import { toast } from 'react-toastify';
const browser = Bowser.getParser(window.navigator.userAgent);
const broswerMeta = JSON.parse(JSON.stringify(browser)).parsedResult;
const formConfig:{
    defaultValues: SignupData
} = {
    defaultValues: {
        email: '',
        first_name: '',
        last_name: '',
        business_name: '',
        business_url: '',
        annual_turnover: '',
        industry: '',
        annual_turnover_currency: 'SGD',
        credit_required: '',
        credit_required_currency: 'USD',
        plan_to_use_for: [],
        notify_on_availability: 1,
        contact_number:'',
        browser_metadata: Object.keys(broswerMeta).map(key => ({
            [key]: broswerMeta[key]
        }))
    }
};

export default function useSignUp() {
    const [success, setSuccess] = useState<boolean>()
    const navigate = useNavigate();
    const goToHome = () => navigate(GLOBAL_ROUTES.HOME)
    const register = useRegister();

    const hookForm = useForm(formConfig);

    const submit = () => {
        hookForm.handleSubmit(data => {
            register.mutate(data, {
                onSuccess: () => {
                    // console.log('success', response)
                    setSuccess(true)
                },
                onError: err => {
                    console.log('err', err)
                    toast.error(`Something went wrong. ${err?.message}`, { theme: 'colored', autoClose: 3000 });
                }
            })
        })()
    }

    return {
        navigate,
        goToHome,
        success,
        setSuccess,
        submit,
        ...hookForm
    }
}
