import './InputGroup.scss'

export default function InputGroup(props: {
    label: string;
    id?: string;
    labelClassName?: string, 
    inputElement: JSX.Element;
    rightElement?: JSX.Element; 
    errorMessage?: string}) {
    return (
        <div className='InputGroup'>
            <label htmlFor={props.id} className={`InputGroup__label ${props.labelClassName}`}>{props.label}</label>
            <div className='InputGroup__inputContent'>
                {props.inputElement}
                <div className="InputGroup__group-append">
                    {props.rightElement}
                </div>
            </div>
            {props.errorMessage && <div className='warning-message TextInput__warning-message'>{props.errorMessage}</div>}
        </div>
    )
  }
