/* eslint-disable jsx-a11y/alt-text */
import { ASSETS } from 'global/assets'
import './AboutUsFeeToSignup.scss'
import ApplyNowBtn from 'components/ApplyNowBtn'
import { signUpSteps } from 'containers/AboutUs/aboutUsConfig'
import ContactSales from 'components/ContactSales'

export default function AboutUsFeeToSignup() {
  return (
    <div className='AboutUsFeeToSignup'>
        <img src={ASSETS.images.StormPhone} height={449} width={316} />
        <div>
            <span>It’s free to sign up for Storms</span>
            <div className='AboutUsFeeToSignup__steps'>
                {signUpSteps.map((item, index) => (
                    <div key={index}>
                        <div className='AboutUsFeeToSignup__steps__step'><span>{index + 1}</span></div>
                        <label className='AboutUsFeeToSignup__steps__title'>{item.title}</label>
                        <label className='AboutUsFeeToSignup__steps__description'>{item.description}</label>
                    </div>
                ))}
            </div>
            <div className='AboutUsFeeToSignup__actions'>
                <span><ApplyNowBtn className='AboutUsFeeToSignup__applyBtn'/></span>
                <span><ContactSales className='AboutUsFeeToSignup__contactBtn '/></span>
            </div>
        </div>
    </div>
  )
}
