import axios, { AxiosError } from "axios";
import AppError from "global/utils/AppError";

export const routes = {
    signup: '/signups'
}


export const catchError = (error: any) => {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      const errorResponseData = JSON.parse(axiosError.request.response);
      if (errorResponseData.message || errorResponseData.errors?.message) {
        throw new AppError(
          errorResponseData.message ??
            errorResponseData.errors?.message ??
            'Something went wrong.',
          axiosError.response?.status || 500,
        );
      }
      // const axiosError = error as AxiosError;
      // const apiError = new AppError(
      //   axiosError.message,
      //   axiosError.response?.status || 500,
      // );
      // throw apiError;
    } else {
      throw error;
    }
  };