import ApplyNowBtn from 'components/ApplyNowBtn'
import './HowMuchStormCost.scss'
import ContactSales from 'components/ContactSales'

export default function HowMuchStormCost() {
  return (
    <div className='HowMuchStormCost'>
        <div className='HowMuchStormCost__left'>
            <div className='HowMuchStormCost__left__content'>
                <h1>How much does it cost?</h1>
                <span>Storm card offered to you at no annual cost, pay interest only as you utilise your assigned credit limit</span>
                <div className='HowMuchStormCost__left__content__actions'>
                    <span><ApplyNowBtn className='HowMuchStormCost__left__content__actions__applyBtn'/></span>
                    <span><ContactSales className='HowMuchStormCost__left__content__actions__contactBtn '/></span>
                </div>
            </div>
        </div>
        <div className='HowMuchStormCost__right'></div>
    </div>
  )
}
