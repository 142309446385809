import './WhatsIncluded.scss'

export default function WhatsIncluded() {
  return (
    <div className='WhatsIncluded'>
        <span>What’s Included</span>
        <div>
            <div>Manage your card using the Storm App</div>
            <div>Your Storm card is accepted at millions of merchants worldwide</div>
            <div>Access to a full suite of payment solutions</div>
        </div>
    </div>
  )
}
