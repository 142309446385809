// eslint-disable-next-line no-useless-escape
const emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const emailRules = {
    required: 'Email is required',
    pattern: {
        value: emailPattern,
        message: 'Email is invalid'
    }
};

export const nameRules = (name: string, required?: boolean, moreRulle?: (val: string, required?: boolean) => boolean | string) => ({
    validate: (val:string) => {
        if(moreRulle && val.trim()){
            const moreRule = moreRulle(val.trim(), required);
            if(typeof moreRule === 'string') return moreRule;
        }
        
        if(required) {
            if(!val.trim()) return `${name} is required`;
            return true
        }
        return true;
    }
});

export const urlRules = (name: string, required?: boolean) => ({
    validate: (url:string) => {
        return nameRules(name, required, (url) => {
            if(url) {
                try {
                    new URL(url);
                    return true;
                } catch (err) {
                    return 'Invalid url';
                }
            }
            return true;
        }).validate(url)
    }
})
