import React, { HTMLInputTypeAttribute } from 'react'
import './TextInput.scss'

export default function TextInput(props: {label: string;type: HTMLInputTypeAttribute; id?:string, placeholder?: string, className?: string, labelClassName?: string, inputProps?: any, errorMessage?: string, leftElement?: React.JSX.Element}) {
  return (
    <div className='TextInput'>
        <label htmlFor={props.id} className={`TextInput__label ${props.labelClassName}`}>{props.label}</label>
        <div className='TextInput__container'>
          {props.leftElement ?<div className='TextInput__container__leftElement'>
            {props.leftElement}
          </div> : null}
          <input type={props.type} className={`TextInput__input ${props.className}`} id={props.id} placeholder={props.placeholder} {...props.inputProps}/>
        </div>
        {props.errorMessage && <div className='warning-message TextInput__warning-message'>{props.errorMessage}</div>}
    </div>
  )
}
