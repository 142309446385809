
import { includedInthisCardItems } from 'containers/PricingPage/pricingPageConfig';
import './IncludedInThisCard.scss';

export default function IncludedInThisCard() {
  return (
    <div className='IncludedInThisCard'>
        <h3>Benefits included with your Storm card</h3>
        <div>
            {includedInthisCardItems.map((item, index) => (
                <div key={index}>
                  <item.Icon height={48} width={48}/>
                  <span>{item.text}</span>
                </div>
            ))}
        </div>
    </div>
  )
}
