import { ASSETS } from "global/assets";
import { AboutUsWhyChooseStormBenefitsType } from "./types";

export const abountUsWhyChooseStormItems:AboutUsWhyChooseStormBenefitsType[] = [
    {
        rtl: false,
        img: ASSETS.images.landingpage.LandingPCardPhoneScan,
        title: 'Story 1: Why we started Storm',
        description: [
            'Small businesses form the backbone of the economy, with more than 95% of businesses in Singapore being classified as small.',
            'As small businesses continue to evolve - banks have failed to keep pace. They continue to rely on archaic processes to issue credit and assess creditworthiness.',
            'We at Storm believe that there is a better way to issue credit - we can leverage multiple sources of data available for small businesses to make instant credit decisions and give you access to the business funding that you need when you needed it.'
        ],
    },
    {
        rtl: true,
        img: ASSETS.images.landingpage.LandingPCardHold,
        title: 'Story 2: What we believe ',
        description: [
            'We believe that with the emergence of open banking standards and democratised ownership of business data, it is possible to significantly improve the experience of getting access to credit for small businesses',
            'All we ask is your permission to access your verifiable banking information - whether it be with MyInfo or with your financial institution, to instantly offer you with a simple credit line to address your working capital needs'
        ],
    },
    {
        rtl: false,
        img: ASSETS.images.landingpage.LandingPPhoneScan,
        title: 'Story 3: Access to working capital when you need it',
        description: [
            'Sign up today or contact us to experience access to working capital the way it was intended to be: quick, transparent and without any hidden fees or extra charges',
            'It’s free to sign up and you have nothing to lose - all of your data will be treated with utmost confidentiality'
        ],
    }
];


export const signUpSteps = [
    {
        title: 'Create an account',
        description: 'Signing up for Storm takes minutes for most businesses, and it’s free.'
    },
    {
        title: 'Add an opening balance',
        description: 'Add funds to your Storm account to create an opening balance.'
    },
    {
        title: 'Explore Storm products',
        description: 'Choose with of Storm financial products you need for your business'
    }
]