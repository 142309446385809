import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import axios from 'axios';
import { API_URL } from 'global/config/constants';
import {
    getLocalStorage,
    removeLocalStorage,
    setLocalStorage,
    storageKeys
} from 'global/utils/storage';

let apiTokenAuthorizationInterceptor;
let responseInterceptor;

const axiosInstance = axios.create({
    baseURL: API_URL
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    function(err) {
        // if (err.status === 401) {
        //     queryClient.clear();
        //     clearAllLocalStorage();
        // }

        return err;
    }
);

export { axiosInstance };

export function setAuthToken(token) {
    setLocalStorage(storageKeys.authToken, token);

    // removing the existing token before adding a new one // for some cases, the token isn't properly removed and the new token isn't set. And it can lead of failed api calls
    axiosInstance.interceptors.request.eject(apiTokenAuthorizationInterceptor);
    axiosInstance.interceptors.response.eject(responseInterceptor);

    apiTokenAuthorizationInterceptor = axiosInstance.interceptors.request.use(
        (config) => {
            config.headers.Authorization = `Bearer ${token || ''}`;

            return config;
        }
    );

    responseInterceptor = axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (error?.response?.status === 401) {
                await clearAuthAndStorage();
            }

            return error;
        }
    );
}

export function unsetAuthToken() {
    axiosInstance.interceptors.request.eject(apiTokenAuthorizationInterceptor);
}

export async function clearAuthAndStorage() {
    unsetAuthToken();
    await removeLocalStorage(storageKeys.authToken);
}

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnmount: false,
            refetchOnReconnect: false,
            cacheTime: 1000 * 60 * 60 // 1 hour
        }
    }
});

export const queryCache = new QueryCache({
    onError: (error) => {
        // eslint-disable-next-line no-console
        console.log(error);
    },
    onSuccess: (data) => {
        // eslint-disable-next-line no-console
        console.log(data);

        return data;
    }
});

const storageConfig = {
    setItem: setLocalStorage,
    getItem: getLocalStorage,
    removeItem: removeLocalStorage
};

export const localStoragePersister = createSyncStoragePersister({
    storage: storageConfig
});

persistQueryClient({
    queryClient,
    persister: localStoragePersister
});
