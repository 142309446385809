/* eslint-disable react/jsx-pascal-case */

import { stormBenefits } from 'containers/LandingPage/contentconfigs';
import './StormBenifits.scss';
import { ASSETS } from 'global/assets';

export default function StormBenifits() {
  return (
    <div className='StormBenifits'>
        <label>Storm comparison to alternative options</label>
        <div className='StormBenifits__contents'>
            <div className='StormBenifits__contents__items'>
                {stormBenefits.map((item, index) => (
                    <div className='StormBenifits__contents__items__title' key={index}>
                        <item.Icon width={24}/>
                        <span>{item.title}</span>
                    </div>
                ))}
            </div>
            <div className='StormBenifits__contents__items'>
                <span className='StormBenifits__contents__items__icon'><ASSETS.svgs.Logo width={112} height={39}/></span>

                {stormBenefits.map((item, index) => (
                    <ASSETS.svgs.CheckIcon key={index}/>
                ))}
            </div>
            <div className='StormBenifits__contents__items'>
                <div className='StormBenifits__contents__items__listCTitle'><ASSETS.svgs.CreditCardIcon /> <span>Credit Card</span></div>
                {stormBenefits.map((item, index) => item.creditCard ? <ASSETS.svgs.CheckIcon key={index}/> : <ASSETS.svgs.CloseIcon key={index}/>)}
            </div>
            <div className='StormBenifits__contents__items'>
                <div className='StormBenifits__contents__items__listCTitle'><ASSETS.svgs.Bank /> <span>Bank Loans</span></div>
                {stormBenefits.map((item, index) => item.bank ? <ASSETS.svgs.CheckIcon key={index}/> : <ASSETS.svgs.CloseIcon key={index}/>)}
            </div>
            
        </div>
    </div>
  )
}
