import { creditCardRequiredOptions } from 'containers/SignUp/signUpConfig'
import './CreditRequired.scss'
import { useState } from 'react'

export default function CreditRequired(props: {value?: string, onChange: (v: any) => any, errorMessage?: string}) {
    const [selected, setSelected] = useState<number>()
    const onClick = (index: number) => () => {
        setSelected(index)
        props.onChange(index)
    }
    return (
        <div className='CreditRequired'>
            <label className={`CreditRequired__label`}>Credit Required</label>
            <div>
                {creditCardRequiredOptions.map((item, index) => (
                    <div key={index} onClick={onClick(index)} className='CreditRequired__item'>
                        <div>
                            <span>Up to</span>
                            <span>{item.text}</span>
                        </div>
                        <input type="radio" onChange={onClick(index)} value={item.value} checked={selected === index} name='CreditRequired__item' aria-label="Radio button for following text input"></input>
                    </div>
                ))}

            </div>
            {props.errorMessage && <label className='warning-message TextInput__warning-message'>{props.errorMessage}</label>}
        </div>
    )
}
