/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import ApplyNowBtn from 'components/ApplyNowBtn';
import './GetYourStormCardToday.scss';
import { ASSETS } from 'global/assets';

export default function GetYourStormCardToday() {
  return (
    <div className='GetYourStormCardToday'>
        <img src={ASSETS.images.landingpage.LandingPCircularPattern} className='GetYourStormCardToday__circularPattern' />
        {/* <label className='GetYourStormCardToday__label1'>What are you waiting for?</label> */}
        <label>Get Your Storm Card Today</label>
        <span><ApplyNowBtn className='GetYourStormCardToday__applyBtn'/></span>
    </div>
  )
}
