/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/alt-text */
import PublicPageLayout from "components/PublicPageLayout";
import ShowcaseImages from "./elements/ShowcaseImages";
import './LandingPage.scss';
import ApplyNowBtn from "components/ApplyNowBtn";
import { ASSETS } from "global/assets";
import ChooseStoremItem from "./elements/ChooseStoremItem";
import { whyChooseStormItems } from "./contentconfigs";
import LandingPageFooter from "components/LandingPageFooter";
import GetYourStormCardToday from "components/GetYourStormCardToday";
import StormBenifits from "./elements/StormBenifits";

export default function LandingPage() {
  return (
    <PublicPageLayout>
        <ShowcaseImages />
        <div className="LandingPage__contentCenter">
          <h1 className="LandingPage__contentCenter__sustainable">The Sustainable Payment Card of the Future</h1>
          <div><ApplyNowBtn /></div>
          <div className="LandingPage__contentCenter__card">
            <ASSETS.svgs.CreditCard />
            <ASSETS.svgs.SignupBg1 className='LandingPage__contentCenter__card__bg1'/>
            <ASSETS.svgs.SignupBg2 className='LandingPage__contentCenter__card__bg2'/>
          </div>
          <span className="LandingPage__contentCenter__chooseStorm">Why Choose Storm?</span>
        </div>
        <div className="LandingPage__whyChooseStorm">
          {whyChooseStormItems.map((item, index) => {
            return <ChooseStoremItem key={index} item={item}/>
          })}
        </div>
        <StormBenifits />
        <GetYourStormCardToday />
        <LandingPageFooter />
    </PublicPageLayout>
  )
}



