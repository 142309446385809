import React from 'react';
import './App.css';
import '@coreui/coreui/dist/css/coreui.min.css'
import CreateRouter from 'global/routes/CreateRoutes';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'global/api';
import ReactGA from 'react-ga4';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  ReactGA.initialize('G-6WTX7PP5C2');
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <CreateRouter />
      </div>
      <ToastContainer />
    </QueryClientProvider>
  );
}

export default App;
