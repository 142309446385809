/* eslint-disable jsx-a11y/alt-text */
import './FeaturedSection.scss';

export default function FeaturedSection(props: {title: string; description: string}) {
  return (
    <div className="FeaturedSection">
        {/* <img src={ASSETS.images.CardBG} width={'100%'} height={'100%'} style={{
            objectFit: 'cover'
        }}/> */}
        <div className='FeaturedSection__content'>
            <div className='title'>{props.title}</div>
            <div className='description'>{props.description}</div>
        </div>
    </div>
  )
}
