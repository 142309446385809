import LogoImage from 'global/assets/images/logo.png';

import LandingPImg1 from 'global/assets/images/landingpage/img1.jpg';
import LandingPImg2 from 'global/assets/images/landingpage/landing-img2.jpeg';
import LandingPImg3 from 'global/assets/images/landingpage/girl-laptop-card-min.jpeg';
import LandingPImg4 from 'global/assets/images/landingpage/people-discuss-min.jpeg';
import LandingPImg5 from 'global/assets/images/landingpage/man-card-smlile.jpeg';
import LandingPImg6 from 'global/assets/images/landingpage/couple-card-laptop.jpeg';
import LandingPImgAsian1 from 'global/assets/images/landingpage/asian-1.jpeg';
import LandingPImgAsian2 from 'global/assets/images/landingpage/asian-2.jpeg';
import LandingPImgAsian3 from 'global/assets/images/landingpage/asian-3.jpeg';
import LandingPImgAsian4 from 'global/assets/images/landingpage/asian-4.jpeg';
import LandingPImgAsian5 from 'global/assets/images/landingpage/asian-5.jpeg';
import LandingPImgAsian6 from 'global/assets/images/landingpage/asian-6.jpeg';
import LandingPCardHold from 'global/assets/images/landingpage/card-hold.png';
import LandingPCardPhoneScan from 'global/assets/images/landingpage/card-phone-scan.png';
import LandingPCardPhone from 'global/assets/images/landingpage/card-phone.png';
import LandingPCardScan from 'global/assets/images/landingpage/card-scan.png';
import LandingPLaptopCard from 'global/assets/images/landingpage/laptop-card.png';
import LandingPPhoneScan from 'global/assets/images/landingpage/phone-scan.png';
import LandingPCircularPattern from 'global/assets/images/landingpage/circular-pattern.png';
import CardBG from 'global/assets/images/card-bg.png';
import StormPhone from 'global/assets/images/storm-phone.png';
import PhoneCard from 'global/assets/images/phone-card.png';
import SignupBg from 'global/assets/images/signup-bg.png';

import { ReactComponent as CreditCard } from 'global/assets/svgs/credit-card.svg';
import { ReactComponent as Access } from 'global/assets/svgs/access.svg';
import { ReactComponent as Airplane } from 'global/assets/svgs/airplane.svg';
import { ReactComponent as Card2 } from 'global/assets/svgs/card-2.svg';
import { ReactComponent as Card } from 'global/assets/svgs/card.svg';
import { ReactComponent as Lock } from 'global/assets/svgs/lock.svg';
import { ReactComponent as Pricing } from 'global/assets/svgs/pricing.svg';
import { ReactComponent as Logo } from 'global/assets/svgs/logo.svg';
import { ReactComponent as CircularPatern } from 'global/assets/svgs/circlular-pattern.svg';
import { ReactComponent as CloseIcon } from 'global/assets/svgs/icon-close.svg';
import { ReactComponent as CheckIcon } from 'global/assets/svgs/icon-check.svg';
import { ReactComponent as DropDownIcon } from 'global/assets/svgs/dropdwon-icon.svg';
import { ReactComponent as SignupBg1 } from 'global/assets/svgs/signup-bg1.svg';
import { ReactComponent as SignupBg2 } from 'global/assets/svgs/signup-bg2.svg';
import { ReactComponent as FluentMoney } from 'global/assets/svgs/fluent_money-20-filled.svg';
import { ReactComponent as CubeTransparent } from 'global/assets/svgs/ph_cube-transparent-fill.svg';
import { ReactComponent as ICBaselineCloude } from 'global/assets/svgs/ic_baseline-cloud.svg';
import { ReactComponent as SolarDocument } from 'global/assets/svgs/solar_document-bold.svg';
import { ReactComponent as HealthSchedule } from 'global/assets/svgs/healthicons_i-schedule-school-date-time.svg';
import { ReactComponent as MDIApproval } from 'global/assets/svgs/mdi_approval.svg';
import { ReactComponent as MingcuteSend } from 'global/assets/svgs/mingcute_send-fill.svg';
import { ReactComponent as TriangelDown } from 'global/assets/svgs/akar-icons_triangle-down-fill.svg';
import { ReactComponent as TeenyMobile } from 'global/assets/svgs/teenyicons_mobile-solid.svg';
import { ReactComponent as ICBaselineStore } from 'global/assets/svgs/ic_baseline-store.svg';
import { ReactComponent as FluentPayment } from 'global/assets/svgs/fluent_payment-16-filled.svg';
import { ReactComponent as AkarPercentage } from 'global/assets/svgs/akar-icons_percentage.svg';
import { ReactComponent as Bank } from 'global/assets/svgs/bxs_bank.svg';
import { ReactComponent as CreditCardIcon } from 'global/assets/svgs/mdi_credit-card.svg';
const PrivacyPolicy = require('global/assets/files/privacy-policy.pdf');

export const ASSETS = {
    images: {
        LogoImage,
        landingpage: {
            LandingPImg1,
            LandingPImg2,
            LandingPImg3,
            LandingPImg4,
            LandingPImg5,
            LandingPImg6,
            LandingPImgAsian1,
            LandingPImgAsian2,
            LandingPImgAsian3,
            LandingPImgAsian4,
            LandingPImgAsian5,
            LandingPImgAsian6,
            LandingPCardHold,
            LandingPCardPhoneScan,
            LandingPCardPhone,
            LandingPCardScan,
            LandingPLaptopCard,
            LandingPPhoneScan,
            LandingPCircularPattern,

        },
        CardBG,
        StormPhone,
        PhoneCard,
        SignupBg
    },
    svgs: {
        CreditCard,
        Access,
        Airplane,
        Card2,
        Card,
        Lock,
        Pricing,
        Logo,
        CircularPatern,
        CloseIcon,
        CheckIcon,
        DropDownIcon,
        SignupBg1,
        SignupBg2,
        FluentMoney,
        CubeTransparent,
        ICBaselineCloude,
        SolarDocument,
        HealthSchedule,
        MDIApproval,
        MingcuteSend,
        TriangelDown,
        TeenyMobile,
        ICBaselineStore,
        FluentPayment,
        AkarPercentage,
        Bank,
        CreditCardIcon
    },
    files: {
        PrivacyPolicy
    }
}