
// const protectedRoutes = [
// ];

import { Route } from "global/routes/types";
import { GLOBAL_ROUTES } from "./constants";
import LandingPage from 'containers/LandingPage'
import AboutUs from "containers/AboutUs";
import PricingPage from "containers/PricingPage";
import SignUp from "containers/SignUp";
import PrivacyPolicyPage from "containers/PrivacyPolicy";

const publicRoutes:Route[] = [
    {
        path: GLOBAL_ROUTES.HOME,
        element: LandingPage
    },
    {
        path: GLOBAL_ROUTES.ABOUT_US,
        element: AboutUs
    },
    {
        path: GLOBAL_ROUTES.PRICING,
        element: PricingPage
    },
    {
        path: GLOBAL_ROUTES.REGISTER,
        element: SignUp
    },
    {
        path: GLOBAL_ROUTES.PRIVACY_POLICY,
        element: PrivacyPolicyPage
    }
    
];

export const routes:Route[] = [
    ...publicRoutes,
    // ...protectedRoutes.map((route) => ({ ...route, isProtected: true }))
];
