/* eslint-disable react/jsx-pascal-case */
import Button from 'components/Button'
import PublicPageLayout from 'components/PublicPageLayout'
import { ASSETS } from 'global/assets'
import './SignUp.scss'
import TextInput from 'components/TextInput'
import InputGroup from 'components/InputGroup'
import CreditRequired from './elements/CreditRequired'
import PlanToUseStorm from './elements/PlanToUseStorm'
import WhatsIncluded from './elements/WhatsIncluded'
import SubmitSuccess from './elements/SubmitSuccess'
import useSignUp from './useSignUp'
import { emailRules, nameRules, urlRules } from 'global/utils/formHelpers'
import { Controller } from 'react-hook-form'
import { GLOBAL_ROUTES } from 'global/config/constants'
import { Link } from 'react-router-dom'
import SelectSearch from 'components/SelectSearch'
const industries = [
    { value: 'Activities of Extra-Territorial Organisations and Bodies', label: 'Activities of Extra-Territorial Organisations and Bodies' },
    { value: 'Activities of Households as Employers of Domestic Personnel', label: 'Activities of Households as Employers of Domestic Personnel' },
    { value: 'Accommodation and Food Service Activities', label: 'Accommodation and Food Service Activities' },
    {value: 'Administrative and Support Service Activities', label: 'Administrative and Support Service Activities'},
    {value: 'Agriculture and Fishing', label: 'Agriculture and Fishing'},
    {value: 'Arts, Entertainment and Recreation', label: 'Arts, Entertainment and Recreation'},
    {value: 'Construction', label: 'Construction'},
    {value: 'Education', label: 'Education'},
    {value: 'Electricity, Gas, Steam and Air-Conditioning Supply', label: 'Electricity, Gas, Steam and Air-Conditioning Supply'},
    {value: 'Financial and Insurance Activities', label: 'Financial and Insurance Activities'},
    {value: 'Health and Social Services', label: 'Health and Social Services'},
    {value: 'Information and Communications', label: 'Information and Communications'},
    {value: 'Manufacturing', label: 'Manufacturing'},
    {value: 'Mining and Quarrying', label: 'Mining and Quarrying'},
    {value: 'Other Service Activities', label: 'Other Service Activities'},
    {value: 'Professional, Scientific and Technical Activities', label: 'Professional, Scientific and Technical Activities'},
    {value: 'Public Administration and Defence', label: 'Public Administration and Defence'},
    {value: 'Real Estate Activities', label: 'Real Estate Activities'},
    {value: 'Transportation and Storage', label: 'Transportation and Storage'},
    {value: 'Water Supply; Sewerage, Waste Management and Remediation Activities', label: 'Water Supply; Sewerage, Waste Management and Remediation Activities'},
    {value: 'Wholesale and Retail Trade', label: 'Wholesale and Retail Trade'},
]

const anualTurnover = [
    { value: '$100,000', label: '$100,000' },
    { value: '$100,000-$250,000', label: '$100,000-$250,000' },
    { value: '$250,000-$500,000', label: '$250,000-$500,000' },
    { value: '$500,000-$1,000,000', label: '$500,000-$1,000,000' },
    { value: '$1,000,000 and above', label: '$1,000,000 and above' },
]
export default function SignUp() {
    const form = useSignUp();
    return (
        <PublicPageLayout headerChildrens={(
            <>
                <Link to={GLOBAL_ROUTES.HOME}>
                    <ASSETS.svgs.Logo width={145} height={51} />
                </Link>

                <Button text='Back to Homepage' onClick={form.goToHome} className='SignUp__goback'/>
            </>
        )}>
            <div className='SignUp'>
                <div className='SignUp__form'>
                    <div className='SignUp__form__name'>
                        <TextInput type='text' label='First Name' inputProps={{ ...form.register('first_name', nameRules('First name', true)) }} errorMessage={form.formState.errors.first_name?.message}/>
                        <TextInput type='text' label='Last Name' inputProps={{ ...form.register('last_name', nameRules('Last name', true)) }} errorMessage={form.formState.errors.last_name?.message}/>
                    </div>
                    <div className='SignUp__form__name'>
                        <TextInput type='text' label='Business Name' inputProps={{ ...form.register('business_name', nameRules('Business name', true)) }} errorMessage={form.formState.errors.business_name?.message}/>
                        <TextInput type='text' label='Business URL (Optional)' inputProps={{ ...form.register('business_url') }} errorMessage={form.formState.errors.business_url?.message}/>
                    </div>

                    <Controller
                        control={form.control}
                        render={({field: {onChange}}) => (
                            <SelectSearch onChange={(val: any) => {
                                onChange(val.value)
                            }} label='Industry' placeholder='' options={industries} errorMessage={form.formState.errors.industry?.message}/>
                        )}
                        rules={{required: 'This is required.'}}
                        name="industry"
                    />

                    <Controller
                        control={form.control}
                        render={({field: {onChange, value}}) => (
                            <InputGroup
                                id='annual-turnover'
                                label='Annual Turnover'
                                rightElement={<span style={{fontSize: '14px'}}>SGD</span>}
                                inputElement={<SelectSearch onChange={(val: any) => {
                                    onChange(val.value)
                                }} placeholder='' options={anualTurnover} containerClass='SignUp__InputGroup'/>}
                                errorMessage={form.formState.errors.annual_turnover?.message}/>
                        )}
                        name="annual_turnover"
                    />
                    
                    <Controller
                        control={form.control}
                        render={({field: {onChange, value}}) => (
                            <CreditRequired onChange={onChange} value={value} errorMessage={form.formState.errors.credit_required?.message}/>
                        )}
                        rules={{required: 'This is required.'}}
                        name="credit_required"
                    />
                    
                    <Controller
                        control={form.control}
                        render={({field: {onChange, value,}}) => (
                            <PlanToUseStorm onChange={onChange} value={value} errorMessage={form.formState.errors.plan_to_use_for?.message}/>
                        )}
                        rules={{validate: (p, dd) => {
                            if(p.length) return true;
                            return 'This is required';
                        }}}
                        name="plan_to_use_for"
                    />
                    <TextInput type='email' label='Email' inputProps={{ ...form.register('email', emailRules)}} errorMessage={form.formState.errors.email?.message}/>
                    <TextInput type='text' label='Contact No. (Optional)' inputProps={{ ...form.register('contact_number', {validate: (p, dd) => {
                            if(p) {
                                if(p.toString().length !== 8 || !p.toString().split('').every(d => Number(d) >= 0 && Number(d)<=9)) {

                                    return 'Invalid contact'
                                }
                                return true;
                            }
                            return true;
                        }})}} leftElement={<span>+65</span>} errorMessage={form.formState.errors.contact_number?.message}/>


                    <WhatsIncluded />

                    <div className='SignUp__form__contact'>
                        <span>We will contact you as soon as the card is launched</span>
                    </div>

                    <Button text='Submit' onClick={form.submit}/>
                </div>
                
                {form.success && <SubmitSuccess goToHome={form.goToHome}/>}
            </div>
        </PublicPageLayout>
    )
}
