/****
 *
 * window & process.env constants
 *
 */

// import { STUDENT_ROUTES } from 'dashboard/config/constants';

export const APP_ENV = process.env.REACT_APP_ENV;
export const API_URL = process.env.REACT_APP_API_URL;
// console.log('API_URL', API_URL)

/****
 *
 * Configs
 *
 */
// App default language (also used for the <html /> lang='' attribute)
// see https://developer.paciellogroup.com/blog/2016/06/using-the-html-lang-attribute/
// NOTE: make sure the language key defined here matches the default helmet key
// // see `executors/i18n/helmet`
export const APP_LANGUAGE_DEFAULT = 'en'; // TODO_REPLACE(en | de | fr | etc..)

// App environments
// see .env.production & .env.development
// values must match the values defined in these files
export const APP_ENVS = {
    LOCAL: 'local',
    STAGING: 'staging',
    PRODUCTION: 'production'
};

export const GLOBAL_ROUTES = {
    HOME: '/',
    REGISTER: '/register',
    ABOUT_US: '/about-us',
    PRICING: '/pricing',
    PRIVACY_POLICY: '/privacy-policy',
};


export const defaultHiddenPassword = '********';
