import { SignupData } from "containers/SignUp/types";
import { axiosInstance } from ".";
import { catchError, routes } from "./config";
import { useMutation } from "@tanstack/react-query";

const register = async (params:SignupData) => {
    const response = await axiosInstance.post(routes.signup, params)
    const resp = JSON.parse(JSON.stringify(response));
    if(resp.status === 500) {
        // console.log(typeof response, {...response})
        catchError(response);
    }
    return response.data;
}


export const useRegister = () => {
    const mutation = useMutation({
        mutationFn: register, 
    })

    return mutation
}