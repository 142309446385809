import LandingPageFooter from 'components/LandingPageFooter'
import PublicPageLayout from 'components/PublicPageLayout'
import FeaturedSection from '../../components/FeaturedSection'
import { abountUsWhyChooseStormItems } from './aboutUsConfig'
import AboutUsChooseStoremItem from './elements/AboutUsChooseStoremItem'
import AboutUsFeeToSignup from './elements/AboutUsFeeToSignup'
import './AboutUs.scss';

export default function AboutUs() {
  return (
    <PublicPageLayout>
        <FeaturedSection title='Challenge the status quo' description='Storm was created to be different. It was created to offer your small business with instant credit when you need it, without the hassle of applying for loans or credit cards online' />
        <div className="AboutUs__whyChooseStorm">
          {abountUsWhyChooseStormItems.map((item, index) => {
            return <AboutUsChooseStoremItem key={index} item={item}/>
          })}
        </div>
        <AboutUsFeeToSignup />
        <LandingPageFooter />
    </PublicPageLayout>
  )
}
