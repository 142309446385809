import Checkbox from 'components/Checkbox'
import './PlanToUseStorm.scss'

export default function PlanToUseStorm<e> (props: {onChange: (val:e[]) => any, value?: e[], errorMessage?: string}) {

    const onChange = (value: e) => (e:any) => {
        const index = props.value?.findIndex?.(d => d === value)
        if(index !== undefined && index >= 0){
            props.value?.splice(index, 1);
        } else {
            props.value?.push(value);
        }
        
        if(props.value){
            props.onChange([...props.value])
        }
    }
    return (
        <div className='PlanToUseStorm'>
            <label>Plan to use Storm Card</label>
            <div className='PlanToUseStorm__items'>
                {options.map((i, index) => (
                    <div key={index}>
                        <Checkbox value={i.value} checked={props.value?.includes?.(i.value as e)} onChange={onChange(i.value as e)}/>
                        <span onClick={onChange(i.value as e)}>{i.text}</span>
                    </div>
                ))}
            </div>
            {props.errorMessage && <label className='warning-message'>{props.errorMessage}</label>}
        </div>
    )
}


const options = [
    {
        text: 'Travel & Entertainment',
        value: 'Travel & Entertainment'
    },
    {
        text: 'Procurement',
        value: 'Procurement'
    },
    {
        text: 'General Expenses',
        value: 'General Expenses'
    },
    {
        text: 'Buying goods and services to grow',
        value: 'Buying goods and services to grow'
    },
    {
        text: 'Other',
        value: 'Other'
    }
]
