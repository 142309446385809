import LandingPageFooter from 'components/LandingPageFooter'
import PublicPageLayout from 'components/PublicPageLayout'
import FeaturedSection from '../../components/FeaturedSection'
import GetYourStormCardToday from 'components/GetYourStormCardToday'
import IncludedInThisCard from './elements/IncludedInThisCard'
import StormBusinessCard from './elements/StormBusinessCard'
import HowMuchStormCost from './elements/HowMuchStormCost'

export default function PricingPage() {
  return (
    <PublicPageLayout>
        <FeaturedSection title='The sustainable small business credit card' description='Storm is designed from the ground up to meet your business’s changing needs'/>
        <HowMuchStormCost />
        <StormBusinessCard />
        <IncludedInThisCard />
        <GetYourStormCardToday />
        <LandingPageFooter />
    </PublicPageLayout>
  )
}
