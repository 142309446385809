/* eslint-disable jsx-a11y/alt-text */
import './AboutUsChooseStoremItem.scss';
import { AboutUsWhyChooseStormBenefitsType } from 'containers/AboutUs/types';

export default function AboutUsChooseStoremItem(props: {item: AboutUsWhyChooseStormBenefitsType}): JSX.Element {
    const Image = () => (
        <img src={props.item.img} className='AboutUsChooseStoremItem__img'/>
    )
    const Content = () => {
        return (
            <div className='AboutUsChooseStoremItem__textContent'  style={{
                alignItems: props.item.rtl ? 'flex-start' : 'flex-end'
            }}>
                <div className='AboutUsChooseStoremItem__textContent__content'>
                    <div className='AboutUsChooseStoremItem__textContent__content__title'>{props.item.title}</div>
                    <div className='AboutUsChooseStoremItem__textContent__content__descriptionsContainer'>
                        {props.item.description.map((des, index) => (
                            <div key={index} className='AboutUsChooseStoremItem__textContent__content__description'>{des}</div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className='AboutUsChooseStoremItem' >
            {props.item.rtl ? (<>
                <Content />
                <Image />
            </>):(<>
                <Image />
                <Content />
            </>)}
        </div>
    )
}
