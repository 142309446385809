export const creditCardRequiredOptions = [
    {
        text: '$10,000',
        value: 10000
    },{
        text: '$25,000',
        value: 25000
    },{
        text: '$50,000',
        value: 50000
    }
]