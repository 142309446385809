import Button from 'components/Button'

export default function ContactSales(props: {className?: string}) {
  const onClick = () => {
    window.location.href = "mailto:info@gostorm.app";
  }
  return (
    <Button text='Contact Sales' onClick={onClick} className={props.className}/>
  )
}
