import Button from "components/Button";
import { GLOBAL_ROUTES } from "global/config/constants";
import { useNavigate } from "react-router-dom";

export default function ApplyNowBtn(props: {className?: string}) {
  const navigation = useNavigate();
  const onClick = () => {
    navigation(GLOBAL_ROUTES.REGISTER)
  }
  return (
    <Button text='Apply Now' onClick={onClick} className={props.className}/>
  )
}
